import { injectIntl } from "gatsby-plugin-intl"
import React from "react"

import {
  localize,
  createMarkup,
  getImageUrl,
  stripHtml,
  getGalleryFromMarkup,
} from "../utils"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../components/image"
import Carousel from "../components/carousel"

const ArticlePage = ({ pageContext, intl }) => {
  const articleData = pageContext.article
  const article = localize(articleData, intl.locale)
  const images = getGalleryFromMarkup(article.body)
  return (
    <Layout name="p-article">
      <Seo
        title={article.title}
        imageUrl={getImageUrl(article.cover_image)}
        description={stripHtml(article.body)}
      />
      <div className="p-article__header">
        <div className="a-container">
          <Image className="p-article__cover"  type="fluid" image={article.cover_image} />
        </div>
      </div>

      <div className="a-container">
        <div className="p-article__content">
          <div className="p-article__main">
            <h1 class="a-section__title a-section__title--main a-section__title--decoration">
              {article.title}
            </h1>
            <div className="p-article__body">
              <div
                dangerouslySetInnerHTML={createMarkup(
                 (article.body || '').replace(/\[galleria=(.)*]/g, "")
                )}
              />
            </div>
          </div>
        </div>
      </div>
      {images && images.length > 0 && <div className="a-container">
        <div className="p-article__carousel">
          <Carousel images={images} />
        </div>
      </div>}
    </Layout>
  )
}

export default injectIntl(ArticlePage)
